import React from 'react'
import SEO from "../../components/atoms/seo"

import Layout from '../../components/organisms/layout'
import Heading from '../../components/molecules/heading'
import Questionnaire from '../../components/organisms/questionnaire'
import KeyFeatures from '../../components/molecules/key-features'

import steps from "../../data/pages/tub/steps.json"
import verifyInformation from "../../data/pages/tub/verify.json"
import content from "../../data/pages/tub/content.json"

const QuestionnairePage = () => (
  <Layout type='tub' showBG showFormSecureBanner>
    <SEO title={content.seo.title} />
    <br />
    <br />
    <br />
    <Heading 
        title=''
        subTitle=''
        weight='regular'
    />
    <Questionnaire 
      steps={steps}
      verifyInformation={verifyInformation}
      disclaimer={content.disclaimer}
      onSubmitMessage={content.onSubmit.message}
      thankYouURL={content.onSubmit.thankYouURL}
      formType="tub"
      thankYouChatBot
    />
    <KeyFeatures
      type="tub"
      features={[
        {
            title: 'Great Service',
            image: 'great-service.png',
            imageAlt: ''
        },
        {
          title: 'As Little as 1-day Turnaround',
          image: 'turnaround.png',
          imageAlt: ''
        },
        {
            title: '5-Star Quality',
            image: '5-stars.png',
            imageAlt: ''
        }
    ]}
    />
  </Layout>
)

export default QuestionnairePage
